import router from '../router'
function daysBetween(date1, date2) {
    const msPerDay = 24 * 60 * 60 * 1000;
    const date1Obj = new Date(date1);
    const date2Obj = date2;
    const time1 = date1Obj.getTime();
    const time2 = date2Obj.getTime();
    const days = Math.round((time2 - time1) / msPerDay);
    return days;
}
router.beforeEach((to, from, next) => {
    if ((to.name != 'login' && !localStorage.getItem('tokenTime')) || (to.name != 'login' && !localStorage.getItem('tokenTime') && daysBetween(localStorage.getItem('tokenTime'),new Date()) > 7)) {
        next({ name: 'login' })
    } else {
        if(to.name=='login'&&localStorage.getItem('tokenTime')&&daysBetween(localStorage.getItem('tokenTime'),new Date())<=7){
            next('/insectModel')
        }else{
            next()
        }
    }
})