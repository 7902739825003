import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)
const routes = [
  { path: '/', redirect: "/login", },
  {
    path: "/login/:id?",
    name: "login",
    component: () => import('../views/login.vue'),
  },
  {
    path: "/insectModel",
    name: "insectModel",
    component: () => import('../views/model/insectModel/index.vue')
  },
  {
    path: "/grow",
    name: "grow",
    component: () => import('../views/model/grow/index.vue')
  },
  {
    path: "/fertility",
    name: "fertility",
    component: () => import('../views/model/fertility/index.vue')
  },
  {
    path: "/production",
    name: "production",
    component: () => import('../views/model/production/index.vue')
  },
  {
    path: "/grass",
    name: "grass",
    component: () => import('../views/model/grass/index.vue')
  },
  {
    path: "/insectYg",
    name: "insectYg",
    component: () => import('../views/model/insectYg/index.vue')
  },
  {
    path: "/farmWork",
    name: "farmWork",
    component: () => import('../views/farmWork/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router