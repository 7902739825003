// 引入vue
import Vue from 'vue'
// 引入vuex
import Vuex from 'vuex'
// 应用Vux插件
Vue.use(Vuex)

//1. state用于存放数据
const state = {
  name: 'LeeYUFan',
  sex: '男',
  age: 20 //周岁
}
// 2.getter用于state中数据加工
const getters = {
  nominalAge(state) {
    return state.age + 1
  }
}
// 3.mutations用于操作数据
const mutations = {
  // 修改姓名
  updateName(state, val) {
    console.log(state, val)
    state.name = val
  },
  // 修改性别
  updateSex(state, val) {
    state.sex = val
  },
  updateAge(state,val){
    state.age = val
  }
}
// 4.actions用于响应组件中的动作
const actions = {
  // 两秒之后进行性别的修改
  delayUpdateSex(content, val) {
    // 直接通过改变state中的数据
    // content.state.sex = val

    // 通过调用mutations对象中的函数对state中的数据进行操作
    setTimeout(() => {
      content.commit('updateSex', val)
      console.log('我是3秒后才执行的函数')
    }, 3000)
  }
}
// 创建并暴露store
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})