<template>
  <div id="app">
    <div class="mainLeft" v-if="ifShow">
      <el-menu
        :default-active="active"
        @select="menueChange"
        class="el-menu-vertical-demo"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>预测分析模型</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/insectModel">病虫害随手拍识别模型</el-menu-item>
            <el-menu-item index="/grow">作物长势监测模型</el-menu-item>
            <el-menu-item index="/fertility"
              >土壤肥力建模与施肥决策</el-menu-item
            >
            <el-menu-item index="/production">作物产量预估模型</el-menu-item>
            <el-menu-item index="/grass">杂草监测与除草作业决策</el-menu-item>
            <el-menu-item index="/insectYg"
              >作物病虫害遥感监测预警模型</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="/farmWork">
          <i class="el-icon-setting"></i>
          <span slot="title">智能农事辅助</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="mainright" v-if="ifShow">
      <router-view />
    </div>
    <div class="mainright1" v-if="!ifShow">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      active: "",
      ifShow:false,
    };
  },
  methods: {
    menueChange(e) {
      if (e != this.$route.path) {
        this.$router.push(e);
      }
    },
  },
  created() {
    this.active = this.$route.path;
   
  },
  watch: {
    // 监听，当路由发生变化的时候执行
    $route(to, from) {
      this.active = to.path;
      if(localStorage.getItem('tokenTime')){
      this.ifShow = true
    }else{
      this.ifShow = false
    }
    },
  },
};
</script>

<style>
#app {
  display: flex;
}
.mainLeft {
  width: 230px;
  height: 100px;
}
.mainright {
  width: calc(100vw - 230px);
}
.mainright1{
  width: 100vw;
}
.el-menu-vertical-demo {
  height: 100vh;
}
</style>
